import React, { useState, useEffect } from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./Generator.css";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Navbar";
import axios from "axios";

let jobSheetTitle = [
  { value: "excelId", name: "Id", checked: false },
  { value: "State", name: "State", checked: false },
  { value: "SAN", name: "SAN", checked: false },
  { value: "Owner1", name: "Owner 01", checked: false },
  { value: "PropertyStatus", name: "Property Status", checked: false },
  { value: "PropAddr1", name: "Property Address 01", checked: false },
  { value: "PropAddr2", name: "Property Address 02", checked: false },
  { value: "PropAddr3", name: "Property Address 03", checked: false },
  { value: "PropAddr4", name: "Property Address 04", checked: false },
  { value: "PropAddr5", name: "Property Address 05", checked: false },
  { value: "Suburb", name: "Suburb", checked: false },
  { value: "Arrears", name: "Arrears", checked: false },
  { value: "CurrentBalance", name: "Current Balance", checked: false },
];

const Check = (props) => {
  const handleCheckbox = (value) => {
    const isChecked = !props.checked;
    const index = jobSheetTitle.findIndex((item) => item.value === value);
    if (index !== -1) {
      jobSheetTitle[index].checked = isChecked;
    }
    if (isChecked) {
      props.setSelectedHobby([...props.selectedHobby, value]);
    } else {
      props.setSelectedHobby(props.selectedHobby.filter((item) => item !== value));
    }
  };
  
  return (
    <>
      <div
        className={`${
          props.selectedHobby.length === 0
            ? "deactive"
            : props.selectedHobby.includes(props.value)
            ? "active"
            : "deactive"
        }`}
      >
        <input
          type="checkbox"
          name={props.name}
          value={props.value}
          onChange={(e) => {
            handleCheckbox(props.value);
          }}
          checked={props.checked}
        />{" "}
        <div className="gen-label">{props.label}</div>
      </div>
    </>
  );
};

let payment = [
  { value: "balanceassigned", name: "Balance Assigned", checked: false },
  { value: "balance", name: "Balance", checked: false },
  { value: "currentBalance", name: "Current Balance", checked: false },
  { value: "arrears", name: "Arrears", checked: false },
  { value: "payments", name: "Payments", checked: false },
];
const Payment = (props) => {
  const [isChecked, setIsChecked] = useState(false);
  const handlePaymentCheckbox = (value) => {
    const findPaymentIndex = props.selectedPaymentHobbyy.findIndex(
      (v) => v === value
    );
    for (let bindex = 0; bindex < payment.length; bindex++) {
      if (payment[bindex].value == value) {
        payment[bindex].checked = true;
      }
    }
    if (findPaymentIndex === -1) {
      return props.setSelectedPaymentHobby((array) => [...array, value]);
    }
    return props.setSelectedPaymentHobby((array) =>
      array.filter((v) => v !== value)
    );
  };

  const handleSelected = (e) => {
    return props.setSelect(e.target.value);
  };

  const handleInputVal = (e) => {
    return props.setInputVal(e.target.value);
  };

  return (
    <>
      <div
        className={`${
          props.selectedPaymentHobbyy.length === 0
            ? "deactive"
            : props.selectedPaymentHobbyy.includes(props.value)
            ? "active"
            : "deactive"
        }`}
      >
        <input
          type="checkbox"
          id="myCheck"
          name={props.name}
          value={props.value}
          onChange={(e) => {
            handlePaymentCheckbox(props.value);
            setIsChecked(!isChecked);
          }}
          checked={isChecked || props.checked}
        />{" "}
        <div className="gen-label">{props.label}</div>
        {isChecked ? (
          <div>
            <form className="gen-container2">
              <span>
                <select
                  className="form-select mb-4 gen-form"
                  aria-label="Default select example"
                  name=""
                  defaultValue=""
                  onChange={handleSelected}
                >
                  <option name="" value="" disabled>
                    Select
                  </option>
                  <option name="LND" value=">">
                    Greater than
                  </option>
                  <option name="VACAT" value="<">
                    Less than
                  </option>
                </select>
              </span>
              <span>
                <input
                  type="number"
                  className="form-control gen-form2"
                  placeholder="Add Amount"
                  onChange={handleInputVal}
                />
              </span>
            </form>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

const JobGenerator = (expence) => {
  const [selectedHobby, setSelectedHobby] = useState([]);
  const [selectedPaymentHobbyy, setSelectedPaymentHobby] = useState([]);
  const [val, setVal] = useState([]);
  const [Disabled, setDisabled] = useState(false);
  let navigate = useNavigate();

  const [select, setSelect] = useState([]);
  const [inputVal, setInputVal] = useState([]);

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [showPayment, setShowPayment] = useState(false);

  const handleCheckAll = () => {
    setIsCheckedAll(!isCheckedAll);

    if (!isCheckedAll) {
      setSelectedPaymentHobby(payment.map((pay) => pay.value));
      payment.forEach((pay) => (pay.checked = true));
      setShowPayment(true);
    } else {
      setSelectedPaymentHobby([]);
      payment.forEach((pay) => (pay.checked = false));
      setShowPayment(false);
    }
  };

  const handleAdd = () => {
    const abc = [...val, []];
    setVal(abc);
  };

  const selectedHobbyString = selectedHobby.join(",");
  const valString = val.join(",");

  let selectedPaymentHobbyyString;
  var abc = "";
  var def = "";

  for (let k = 0; k < selectedPaymentHobbyy.length; k++) {
    selectedPaymentHobbyyString =
      selectedPaymentHobbyy[k] + ";" + select + ";" + inputVal;
    abc = abc + "," + selectedPaymentHobbyyString;
    def = abc.substring(1);
  }

  const handleChange = (onChangeValue, i) => {
    const inputdata = [...val];
    inputdata[i] = onChangeValue.target.value;
    setVal(inputdata);
  };

  const handelDelete = (i) => {
    const deleteVal = [...val];
    deleteVal.splice(i, 1);
    setVal(deleteVal);
  };

  const handelDelete1 = (j) => {
    freeData.map((item) => {
      const deleteVal1 = [item.columnName];
      deleteVal1.splice(j, 1);
      setFreeData(deleteVal1);
    });
  };

  const handleSubmit = () => {
    const Auth = localStorage.getItem("userInfor");
    const message = JSON.parse(Auth);
    const AdminId = message.message.id;

    const jobSheet = {
      jobType: "lnds",
      columnName: selectedHobbyString,
      adminId: AdminId,
    };
    if (selectedHobby.length > 0) {
      axios
        .post(`https://admin-api-dev.apancollections.com/accessColumns`, jobSheet)
        .then((result) => {
          setDisabled(false);
        })
        .catch((error) => {
          setDisabled(false);
          console.log(error);
        });
    }

    const jobSheetbalance = {
      jobType: "lnds",
      columnName: def,
      adminId: AdminId,
    };
    if (selectedPaymentHobbyy.length > 0) {
      axios
        .post(`https://admin-api-dev.apancollections.com/paymentColumns`, jobSheetbalance)
        .then((result) => {
          setDisabled(false);
        })
        .catch((error) => {
          setDisabled(false);
          console.log(error);
        });
    }

    const jobSheetFree = {
      jobType: "lnds",
      columnName: valString,
      adminId: AdminId,
    };
    if (val.length > 0) {
      axios
        .post(`https://admin-api-dev.apancollections.com/freeColumns`, jobSheetFree)
        .then((result) => {
          setDisabled(false);
        })
        .catch((error) => {
          setDisabled(false);
          console.log(error);
        });
    }

    var vals;
    if(showPayment == true){
      vals = 1
    } else{
      vals = 0
    }

    const paymentToggle = {
      jobType: "lnds",
      adminId: AdminId,
      vals:vals
    };
      axios
        .post(`https://admin-api-dev.apancollections.com/adminPaymentDetails`, paymentToggle)
        .then((result) => {
          setDisabled(false);
        })
        .catch((error) => {
          setDisabled(false);
          console.log(error);
        });
    navigate("/jobSheet");
  };

  const [accessItems, setAccessItems] = useState([]);
  const [freeData, setFreeData] = useState([]);
  const [jobDataBalance, setJobDataBalance] = useState([]);
  const [togglePayment, setTogglePayment] = useState([]);

  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  const AdminId = message.message.id;

  useEffect(() => {
    axios
      .get(`https://admin-api-dev.apancollections.com/jobsheetbalance/${AdminId}/lnds`)
      .then((responce) => {
        responce.data.forEach((element) => {
          for (let index = 0; index < jobSheetTitle.length; index++) {
            if (element.columnName == jobSheetTitle[index].value) {
              jobSheetTitle[index].checked = true;
            }
          }
        });
        setAccessItems(responce.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (AdminId) {
      axios
        .get(`https://admin-api-dev.apancollections.com/jobsheetfree/${AdminId}/lnds`)
        .then((res) => {
          setFreeData(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (AdminId) {
      axios
        .get(`https://admin-api-dev.apancollections.com/jobsheet/${AdminId}/lnds`)
        .then((res) => {
          res.data.forEach((belement) => {
            for (let bindex = 0; bindex < payment.length; bindex++) {
              if (belement.columnName == payment[bindex].value) {
                payment[bindex].checked = true;
              }
            }
          });
          setJobDataBalance(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  useEffect(() => {
    if (AdminId) {
      axios
        .get(`https://admin-api-dev.apancollections.com/adminPaymentDetails/${AdminId}/lnds`)
        .then((res) => {
          if(res.data.val == 1){
            setIsCheckedAll(true)
            setShowPayment(true)
          } else{
            setIsCheckedAll(false)
            setShowPayment(false)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <Layout>
      <div>
        <div className="Heading">Job Sheet Generator Setup</div>
      </div>
      <br />
      <br />
      <div className="generator-container">
        <span>
          <p className="generator-text">Source: Access</p>
          {jobSheetTitle.map((fruit, key) => (
            <Check
              selectedHobby={selectedHobby}
              setSelectedHobby={setSelectedHobby}
              key={key}
              label={fruit.name}
              name={fruit.name}
              value={fruit.value}
              checked={fruit.checked}
            />
          ))}
        </span>
        <span>
          <p className="generator-text">Source: Payment</p>
          <div>
            <label className="switch">
              <input type="checkbox" onClick={handleCheckAll} checked={isCheckedAll} />
              <span class="slider round"></span>
            </label>
          </div>
          {showPayment ? (
            payment.map((pay, key) => (
              <Payment
                selectedPaymentHobbyy={selectedPaymentHobbyy}
                setSelectedPaymentHobby={setSelectedPaymentHobby}
                key={key}
                label={pay.name}
                name={pay.name}
                value={pay.value}
                select={select}
                setSelect={setSelect}
                inputVal={inputVal}
                setInputVal={setInputVal}
                checked={pay.checked || isCheckedAll}
              />
            ))
          ) : (
            <div></div>
          )}
        </span>
        <span>
          <p className="generator-text">Source: Free Field</p>
          {freeData.map((itemFree, j) => (
            <div className="field-container" style={{ marginBottom: "10px" }}>
              <input
                className="form-control gen-form2"
                value={itemFree.columnName}
              />
              <button className="gen-button" onClick={() => handelDelete1(j)}>
                -
              </button>
            </div>
          ))}
          {val.map((data, i) => {
            return (
              <div className="field-container" style={{ marginBottom: "10px" }}>
                <input
                  className="form-control gen-form2"
                  value={data}
                  onChange={(e) => handleChange(e, i)}
                />
                <button className="gen-button" onClick={() => handelDelete(i)}>
                  -
                </button>
              </div>
            );
          })}
          <div className="gen-container3">
            <button className="gen-button" onClick={() => handleAdd()}>
              +
            </button>
            <p className="gen-text">Add New Field</p>
          </div>
        </span>
      </div>
      <div className="job-container">
        <button className="sheetButton" onClick={handleSubmit} type="submit">
          Save
        </button>
      </div>
    </Layout>
  );
};
export default JobGenerator;
