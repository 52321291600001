import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css"; // import first
import { useToasts } from "react-toast-notifications";
import axios from "../../../axios";
import "./importManger.css";
import Layout from "../../../components/layout/Navbar";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import Datapicker from "../../../components/customCore/datapicker";
import { From } from "devextreme-react/autocomplete";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import ReactPaginate from "react-paginate";

const useStyles = makeStyles((theme) => ({
  Progress: {
    width: "24px !important",
    height: "24px !important",
  },
  formMain: {
    display: "flex",
    justifyContent: "space-evenly",
    maxWidth: "800px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  formMain1: {
    display: "flex",
    maxWidth: "800px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  formMain2: {
    display: "flex",
    maxWidth: "800px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  label: {
    paddingLeft: "22px",
    fontSize: "16px",
    color: "#00000A",
    opacity: "0.6",
    fontWeight: 600,
    paddingBottom: "4px",
    width: "200px",
  },
  label1: {
    paddingLeft: "22px",
    fontSize: "16px",
    color: "#00000A",
    opacity: "0.6",
    fontWeight: 600,
    paddingBottom: "4px",
    width: "200px",
    marginTop: "25px",
  },
  lnds: {
    maxWidth: "500px",
  },
  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "200px !important",
    border: "2px solid #501abf !important",
    outline: "none",
    "&:hover": {
      color: "#501abf !important",

      "& svg": {
        color: "#501abf !important",
      },
      background: "#fff !important",
    },
    "& svg": {
      color: "#fff !important",
    },
  },
}));

export const TypeArray = [
  {
    id: "New",
    name: "New",
  },
  // {
  //   id: "Re Assign",
  //   name: "Re Assign(Task Completed)",
  // },
];
function Dashboard() {
  const { addToast } = useToasts();
  const classes = useStyles();

  //upload file
  const [Date, setDate] = React.useState("");

  const [Form, setForm] = React.useState("");
  const [FormRange, setFormRange] = React.useState("");
  const [TypeOfTask, setTypeOfTask] = React.useState("");
  const [To, setTo] = React.useState("");
  const [ToRange, setToRange] = React.useState("");
  const [BatchnameRange, setBatchNameRange] = React.useState("");
  const [RiderId, setRiderId] = React.useState("");
  const [RiderIdRange, setRiderIdRange] = React.useState("");
  const [RiderData, setRiderData] = useState([]);
  const AdminId = JSON.parse(localStorage.getItem("auth")).message.id || "";
  const [Category, setCategory] = React.useState([]);
  const [UserSelectCategory, setUserSelectCategory] = React.useState("");
  const [UserSelectCategoryCDate, setUserSelectCategoryCDate] =
    React.useState("");
  const [Option, setOption] = useState("");
  const [Disabled, setDisabled] = React.useState(false);
  const [DisabledForRange, setDisabledForRange] = React.useState(false);
  const [DisabledForRangeSubmit, setDisabledForRangeSubmit] =
    React.useState(false);
  const [customIdRange, setCustomIdRange] = useState(false);
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [AllData, setAllData] = useState([]);
  const [selectMultiDeleteRow, setSelectMultiDeleateRow] = React.useState([]);

  useEffect(() => {
    axios
      .get("/rider")
      .then((res) => {
        let data = res.data;
        let filterData = data.filter((item) => item.adminId === AdminId) || [];
        setRiderData(filterData);
      })
      .catch((err) => {
        console.log("error");
      });
  }, []);

  useEffect(() => {
    if (Option) {
      setDisabled(true);
      axios
        .get(`/jobname/${Option}/${AdminId}`)
        .then((res) => {
          setDisabled(false);
          setCategory(res.data);
        })
        .catch((err) => {
          setDisabled(false);
          setCategory([]);
        });
    }
  }, [Option, AdminId]);
  let handleSubmit = (event) => {
    setDisabled(true);
    event.preventDefault();
    const diffrentOfFromAndTo = Number(To) - Number(Form);
    console.log(diffrentOfFromAndTo);
    if (diffrentOfFromAndTo > 300) {
      addToast("The difference between 'To' and 'From' exceeds 300", {
        appearance: "error",
        autoDismiss: "true",
        autoDismissTimeout: 2000,
      });
      setDisabled(false);
    } else {
      const jsonData = {
        type: Option,
        dataFrom: Number(Form),
        dataTo: Number(To),
        riderId: RiderId,
        createdDate: "2022-10-14",
        dueDate: "2022-10-15",
        jobId: UserSelectCategory,
        typeOfTaskAssign: TypeOfTask,
        assignedDate: moment(Date).format("YYYY-MM-DD"),
      };
      axios
        // detailIndex
        .post(`/riderdata`, jsonData, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          // Some data already assigned to riders
          addToast(
            res.data.errorMessage === true
              ? "Some data already assigned to riders"
              : "Assign Data Successfully",
            {
              appearance: res.data.errorMessage === true ? "error" : "success",
              autoDismiss: "true",
              autoDismissTimeout: 2000,
            }
          );
          setTimeout(() => {
            window.location.href = "/assigned-data";
          }, 1000);
          setDisabled(false);
        })
        .catch((error) => {
          addToast("please select correct feild", {
            appearance: "error",
            autoDismiss: "true",
            autoDismissTimeout: 2000,
          });
          setDisabled(false);
        });
    }
  };
  const handleStaffChange = (e) => {
    const { value } = e.target;
    setRiderId(value);
  };
  const handleStaffChangeForCustomRange = (e) => {
    const { value } = e.target;
    setRiderIdRange(value);
  };
  const handleTypeChange = (e) => {
    const { value } = e.target;
    setTypeOfTask(value);
  };
  const handleCategoryChange = (e) => {
    const { value } = e.target;
    const date =
      Category.find((item) => item.id == e.target.value).createdDate || "";
    setUserSelectCategory(value);
    setUserSelectCategoryCDate(moment(date).format("YYYY-MM-DD"));
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setOption(value);
  };

  const handleCheckboxChange = (e) => {
    setCustomIdRange(e.target.checked);
  };

  const handleButtonClick = () => {
    setDisabledForRange(true);
    const diffrenceOfRange = Number(ToRange) - Number(FormRange);
    if (
      FormRange === "" ||
      ToRange === "" ||
      Option === "" ||
      UserSelectCategory === ""
    ) {
      addToast("Please select the range, category, job name", {
        appearance: "error",
        autoDismiss: "true",
        autoDismissTimeout: 3000,
      });
      setDisabledForRange(false);
    } else if (Number(FormRange) > Number(ToRange)) {
      addToast("From range is greater than To range", {
        appearance: "error",
        autoDismiss: "true",
        autoDismissTimeout: 3000,
      });
      setDisabledForRange(false);
    } else if (diffrenceOfRange > 300) {
      addToast("The difference between 'To' and 'From' exceeds 300", {
        appearance: "error",
        autoDismiss: "true",
        autoDismissTimeout: 3000,
      });
      setDisabledForRange(false);
    } else {
      axios
        .get(
          `/riderdata/filterByJobnameAndCategoryCallManager/${Option}/${UserSelectCategory}/2/All/All/${Number(
            FormRange
          )}/${Number(ToRange)}`
        )
        .then((res) => {
          console.log(res.data);
          setData(res.data);
          setAllData(res.data);
          setLoading(true);
          setDisabledForRange(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setDisabledForRange(false);
        });
    }
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(AllData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data, AllData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;

    setItemOffset(newOffset);
  };

  const handleMulatiDeleateRow = (rowId) => {
    if (selectMultiDeleteRow.includes(rowId)) {
      setSelectMultiDeleateRow(
        selectMultiDeleteRow.filter((id) => id !== rowId)
      );
    } else {
      setSelectMultiDeleateRow([...selectMultiDeleteRow, rowId]);
    }
  };

  const handleSubmitForCustomRange = () => {
    setDisabledForRangeSubmit(true);
    const rowIds = selectMultiDeleteRow.map((row) => row.id);
    const stringRowIds = JSON.stringify(rowIds);

    const excelIds = selectMultiDeleteRow.map((row) => row.excelId);
    const excelIdString = JSON.stringify(excelIds);

    if (BatchnameRange === "" || RiderIdRange === "" || TypeOfTask === "") {
      addToast("Please select all fields", {
        appearance: "error",
        autoDismiss: "true",
        autoDismissTimeout: 3000,
      });
      setDisabledForRangeSubmit(false);
    } else {
      const jsonData = {
        type: Option,
        riderId: RiderIdRange,
        dataFrom: Number(FormRange),
        dataTo: Number(ToRange),
        createdDate: "2022-10-14",
        dueDate: "2022-10-15",
        jobId: UserSelectCategory,
        typeOfTaskAssign: TypeOfTask,
        assignedDate: moment(Date).format("YYYY-MM-DD"),
        customsRange: stringRowIds,
        batchName: BatchnameRange,
        excelIDRange: excelIdString,
      };
      axios
        // detailIndex
        .post(`/riderdata`, jsonData, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          // Some data already assigned to riders
          addToast(
            res.data.errorMessage === true
              ? "Some data already assigned to riders"
              : "Assign Data Successfully",
            {
              appearance: res.data.errorMessage === true ? "error" : "success",
              autoDismiss: "true",
              autoDismissTimeout: 2000,
            }
          );
          setTimeout(() => {
            window.location.href = "/assigned-data";
          }, 1000);
          setDisabledForRangeSubmit(false);
        })
        .catch((error) => {
          addToast("please select correct feild", {
            appearance: "error",
            autoDismiss: "true",
            autoDismissTimeout: 2000,
          });
          setDisabledForRangeSubmit(false);
        });
    }
  };

  return (
    <Layout>
      <div className="newFile2  form">
        <div className="rider-info">Assign Data</div>
        <form>
          <div className={classes.formMain}>
            <div className="row">
              <label className={classes.label}>Category</label>
              <select
                className="form-select"
                aria-label="Default select example"
                name=""
                onChange={handleInputChange}
                defaultValue=""
              >
                <option name="" value="" disabled>
                  Select
                </option>
                <option name="LND" value="lnds">
                  LANDED
                </option>
                <option name="VACAT" value="vacants">
                  VACANTS
                </option>
                <option name="COMMERCIAL" value="commercials">
                  COMMERCIAL
                </option>
                <option name="HIGHRISES" value="highrises">
                  HIGHRISES
                </option>
              </select>
              <br />

              <label className={classes.label1}>Job Name</label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleCategoryChange}
                defaultValue=""
              >
                <option name="" value="" disabled>
                  Select
                </option>
                {Category &&
                  Category.length > 0 &&
                  Category.map((item, i) => (
                    <option key={i} name={item.createdAt} value={item.id}>
                      {item.jobName}
                    </option>
                  ))}
              </select>
              <br />
              <br />
              <br />
              <Datapicker
                name="date"
                value={Date}
                label="Date"
                onChange={(e) => setDate(e)}
                required
              />
              <br />
              <label className={classes.label1}>Staff Name</label>

              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleStaffChange}
                defaultValue=""
                disabled={customIdRange}
              >
                <option name="" value="" disabled>
                  Staff Name
                </option>
                {RiderData &&
                  RiderData.length > 0 &&
                  RiderData.map((item, i) => (
                    <option key={i} name={item.id} value={item.id}>
                      {item.fullName}
                    </option>
                  ))}
              </select>
            </div>
            <br />
            <hr />
            <br />
            <br />

            <div className="container1">
              <label className={classes.label}> Type Of Task Assign</label>

              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleTypeChange}
                defaultValue=""
              >
                <option name="" value="" disabled>
                  Select{" "}
                </option>
                {TypeArray &&
                  TypeArray.length > 0 &&
                  TypeArray.map((item, i) => (
                    <option key={i} name={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
              <br />
              <label className={classes.label}>From</label>

              <div className="input-div">
                <input
                  type="text"
                  className="input-div-input"
                  placeholder="From"
                  value={Form}
                  onChange={(e) => setForm(e.target.value)}
                  disabled={customIdRange}
                />
              </div>
              <br />
              <label className={classes.label}>To</label>

              <div className="input-div">
                <input
                  type="text"
                  className="input-div-input"
                  placeholder="To"
                  value={To}
                  onChange={(e) => setTo(e.target.value)}
                  disabled={customIdRange}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              type="button"
              className={`submit3  ${classes.btn} `}
              disabled={customIdRange}
              onClick={handleSubmit}
            >
              {" "}
              {Disabled ? (
                <CircularProgress className={classes.Progress} />
              ) : (
                "Submit"
              )}
            </button>
          </div>
          <hr style={{ margin: "30px 80px" }} />
          <div className={classes.formMain1}>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault1"
                  style={{ cursor: "pointer" }}
                  onChange={handleCheckboxChange}
                />
                <label
                  for="flexCheckDefault1"
                  className={classes.label}
                  style={{ marginTop: "5px", cursor: "pointer" }}
                >
                  Custom ID range
                </label>
              </div>
            </div>
            <div className="container1">
              <div style={{ display: "flex", maxWidth: "300px" }}>
                <label style={{ marginTop: "10px" }} className={classes.label}>
                  From
                </label>

                <div className="input-div">
                  <input
                    type="text"
                    className="input-div-input"
                    placeholder="From"
                    value={FormRange}
                    onChange={(e) => setFormRange(e.target.value)}
                    style={{ width: "100px", marginLeft: "10px" }}
                    disabled={!customIdRange}
                  />
                </div>
                <br />
                <label style={{ marginTop: "10px" }} className={classes.label}>
                  To
                </label>

                <div className="input-div">
                  <input
                    type="text"
                    className="input-div-input"
                    placeholder="To"
                    value={ToRange}
                    onChange={(e) => setToRange(e.target.value)}
                    style={{ width: "100px", marginLeft: "10px" }}
                    disabled={!customIdRange}
                  />
                </div>
              </div>
            </div>
            <div className="container1">
              <div style={{ marginLeft: "100px", marginTop: "5px" }}>
                <button
                  type="button"
                  style={{
                    border: "none",
                    borderRadius: "50%",
                    padding: "7px 13px 10px",
                    background: "#5016bf",
                  }}
                  disabled={!customIdRange}
                  onClick={handleButtonClick}
                >
                  {" "}
                  {DisabledForRange ? (
                    <CircularProgress className={classes.Progress} />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#fff"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className={classes.formMain2}>
            {data && data.length > 0 && customIdRange && (
              <>
                <div className="row">
                  <div className="table-rider">
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                      }}
                    >
                      <tbody>
                        <tr>
                          <th>
                            <Typography variant="body"></Typography>{" "}
                          </th>
                          <th>
                            <Typography variant="body">ID</Typography>{" "}
                          </th>
                          <th>
                            <Typography variant="body">SAN</Typography>{" "}
                          </th>

                          <th>
                            <Typography variant="body">DCA Name</Typography>{" "}
                          </th>
                          <th>
                            {" "}
                            <Typography variant="body">State</Typography>{" "}
                          </th>
                          <th>
                            <Typography variant="body">Status</Typography>{" "}
                          </th>
                          <th>
                            <Typography variant="body">Batch Name</Typography>{" "}
                          </th>
                        </tr>
                      </tbody>
                      <tbody>
                        {!Loading && (
                          <tr
                            style={{
                              borderCollapse: "collapse",
                              padding: "0px !important",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                                padding: "0px !important",
                              }}
                              colSpan="15"
                            >
                              <Box sx={{ width: "100%" }}>
                                <LinearProgress />
                              </Box>
                            </td>
                          </tr>
                        )}
                      </tbody>

                      <tbody>
                        {currentItems &&
                          currentItems.length > 0 &&
                          currentItems.map((data, i) => (
                            <tr key={i}>
                              <td>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                  style={{
                                    cursor: "pointer",
                                    width: "15px",
                                    height: "15px",
                                  }}
                                  onChange={() => handleMulatiDeleateRow(data)}
                                  disabled={data.assignedId != null}
                                  checked={data.assignedId}
                                />
                              </td>
                              <td>
                                <Typography variant="body">
                                  {data.id}
                                </Typography>
                              </td>
                              <td align="center">
                                <p
                                  style={{
                                    textAlign: "left",
                                    margin: "0px",
                                    paddingLeft: "16px",
                                  }}
                                >
                                  {data.SAN}
                                </p>
                              </td>
                              <td>
                                <p
                                  style={{
                                    textAlign: "left",
                                    margin: "0px",
                                    paddingLeft: "16px",
                                  }}
                                >
                                  {data.DCAName}
                                </p>
                              </td>
                              <td>
                                <p
                                  style={{
                                    textAlign: "left",
                                    margin: "0px",
                                    paddingLeft: "16px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {data.State}
                                </p>
                              </td>
                              <td>
                                <p
                                  style={{
                                    textAlign: "left",
                                    margin: "0px",
                                    paddingLeft: "16px",
                                  }}
                                >
                                  {data.assignedId
                                    ? "Assigned"
                                    : "Not Assigned"}
                                </p>
                              </td>
                              <td>
                                {data.batchName ? (
                                  <p
                                    style={{
                                      textAlign: "center",
                                      marginTop: "20px",
                                      backgroundColor: "#0D9276",
                                      color: "#fff",
                                      borderRadius: "20px",
                                      padding: "5px 20px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {data.batchName}
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      textAlign: "center",
                                      marginTop: "25px",
                                    }}
                                  >
                                    -
                                  </p>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      <tbody>
                        {data.length === 0 && Loading && (
                          <tr style={{ borderCollapse: "collapse" }}>
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                              colSpan="15"
                            >
                              <Typography variant="body">No data</Typography>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <br />
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      onPageChange={handlePageClick}
                      pageCount={pageCount}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
                <div className="container1">
                  <label
                    className={classes.label}
                    style={{ marginTop: "20px" }}
                  >
                    Batch Name
                  </label>
                  <div className="input-div">
                    <input
                      type="text"
                      className="input-div-input"
                      placeholder="Batch Name"
                      value={BatchnameRange}
                      onChange={(e) => setBatchNameRange(e.target.value)}
                    />
                  </div>
                  <label className={classes.label1}>Staff Name</label>

                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleStaffChangeForCustomRange}
                    defaultValue=""
                  >
                    <option name="" value="" disabled>
                      Staff Name
                    </option>
                    {RiderData &&
                      RiderData.length > 0 &&
                      RiderData.map((item, i) => (
                        <option key={i} name={item.id} value={item.id}>
                          {item.fullName}
                        </option>
                      ))}
                  </select>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      type="button"
                      className={`submit3  ${classes.btn} `}
                      onClick={handleSubmitForCustomRange}
                    >
                      {" "}
                      {DisabledForRangeSubmit ? (
                        <CircularProgress className={classes.Progress} />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </Layout>
  );
}
export default Dashboard;
