import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import axios from "../../axios";
import AddRider from "../../components/Modal/AddRiderPopup";
import DeleteIcon from "@mui/icons-material/Delete";

import Layout from "../../components/layout/Navbar";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import ReactPaginate from "react-paginate";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Link } from "react-router-dom";
import moment from "moment";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useToasts } from "react-toast-notifications";
import AlertBoxImportManager from "../../components/layout/alertBoxImportManager";

const theme = createTheme();

theme.typography.h3 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "1.8rem",
  fontWeight: 400,
  fontStyle: "normal",

  "@media (max-width:850px)": {
    fontSize: "1.5rem",
  },
  "@media (max-width:400px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.8rem",
  },
};
theme.typography.h1 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "2.5rem",
  color: "#5016BF",
  "@media (max-width:800px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};

function Newrider() {
  const [openModel, setOpenModel] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  const token = message.message.token;
  const AdminId = message.message.id;
  const { addToast } = useToasts();

  // paggnination
  const itemsPerPage = 10;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [JobName, setJobName] = useState("");
  useEffect(() => {
    if (AdminId) {
      axios
        .get("/jobname/findAllCountsByAdminId/" + AdminId)
        .then((res) => {
          setData(res.data);
          setLoading(true);
        })
        .catch((error) => {
          setLoading(true);
        });
    }
  }, []);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  const deleteHandle = (e, jobType) => {
    if (e.jobName === jobType) {
      if (e.category === "vacants") {
        axios
          .delete(
            `/jobname/${e.category === "vacants" ? "vacants" : e.category}/${
              e.id
            }`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            window.location.href = "/import-data-table";
          })
          .catch((error) => {
            setLoading(true);
          });
      } else if (e.category === "lnds") {
        axios
          .delete(`/lnds/${e.id}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            window.location.href = "/import-data-table";
          })
          .catch((error) => {
            setLoading(true);
          });
      } else if (e.category === "commercials") {
        axios
          .delete(`/commercials/${e.id}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            window.location.href = "/import-data-table";
          })
          .catch((error) => {
            setLoading(true);
          });
      } else if (e.category === "highrises") {
        axios
          .delete(`/highrises/${e.id}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            window.location.href = "/import-data-table";
          })
          .catch((error) => {
            setLoading(true);
          });
      }
    } else {
      addToast("Enter the correct Job Name", {
        appearance: "error",
        autoDismiss: "true",
        autoDismissTimeout: 2000,
      });
    }
  };
  const Deleting = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AlertBoxImportManager
            onClose={onClose}
            onClick={(jobType) => {
              deleteHandle(e, jobType);
            }}
          />
        );
      },
    });
  };
  const removeZ = (str) => {
    const updatedAt = new Date(str);

    // Add 1 day to the date
    updatedAt.setDate(updatedAt.getDate());

    const formattedDate = `${updatedAt.getDate().toString().padStart(2, "0")}/${(
      updatedAt.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${updatedAt.getFullYear()}`;

    return formattedDate;
  };
  return (
    <Layout title="Rider">
      <div className="rider-tble">
        <div className="rider-head-details">
          <div className="rider-info">Import Manager</div>
          <Link
            style={{ textDecoration: "none", color: "#501abf" }}
            to="/import-data"
          >
            <button className="button-cus">Add New</button>
          </Link>
        </div>
        <div className="table-rider">
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              borderSpacing: 0,
            }}
          >
            <tbody>
              <tr>
                <th>
                  <Typography variant="body">Seq</Typography>{" "}
                </th>
                <th>
                  {" "}
                  <Typography variant="body"> Job Id</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Job Period</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Job Type</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Total Records</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Report Submission Date</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Imported Date</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body"> Action</Typography>{" "}
                </th>
              </tr>
            </tbody>

            <tbody>
              {!Loading && (
                <tr
                  style={{
                    borderCollapse: "collapse",
                    padding: "0px !important",
                  }}
                >
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      padding: "0px !important",
                    }}
                    colSpan="12"
                  >
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  </td>
                </tr>
              )}
            </tbody>

            <tbody>
              {currentItems &&
                currentItems.length > 0 &&
                currentItems.map((data, i) => (
                  <tr key={i}>
                    <td>
                      <Typography variant="body">{data.id}</Typography>
                    </td>
                    <td>
                      <p
                        style={{
                          textAlign: "left",
                          margin: "0px",
                          paddingLeft: "16px",
                        }}
                      >
                        {data.jobName}
                      </p>
                    </td>

                    <td>
                      <Typography variant="body">
                        {removeZ(data.createdDate)} - {removeZ(data.dueDate)}
                      </Typography>
                    </td>
                    <td>
                      <p
                        style={{
                          textAlign: "left",
                          margin: "0px",
                          paddingLeft: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.category === "lnds" ? "Landed" : data.category}
                      </p>
                    </td>
                    <td align="center">
                      {data.lndsCount +
                        data.commercialsCount +
                        data.highrisesCount +
                        data.vacantsCount}
                    </td>
                    <td>
                      <p
                        style={{
                          textAlign: "center",
                          margin: "0px",
                          paddingLeft: "16px",
                        }}
                      >
                        {removeZ(data.reportDate)}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          textAlign: "center",
                          margin: "0px",
                          paddingLeft: "16px",
                        }}
                      >
                        {removeZ(data.createdDate)}
                      </p>
                    </td>
                    <td onClick={() => Deleting(data)}>
                      <DeleteIcon
                        sx={{
                          color: "red",
                          opacity: "0.8",
                          ": &hover": { opacity: "1" },
                          cursor: "pointer",
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
            <tbody>
              {data.length === 0 && Loading && (
                <tr style={{ borderCollapse: "collapse" }}>
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                    colSpan="12"
                  >
                    <Typography variant="body">No data</Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <br />

          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>

      {openModel && <AddRider closeModel={setOpenModel} />}
    </Layout>
  );
}

export default Newrider;
