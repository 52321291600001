import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import axios from "../../../axios";
import AddRider from "../../../components/Modal/AddRiderPopup";
import DeleteIcon from "@mui/icons-material/Delete";
import Layout from "../../../components/layout/Navbar";
import "./rider.css";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import AlertBox from "../../../components/layout/alertBox";
import moment from "moment";
import SearchInput from "../../../components/input/searchInput";
import SearchIcon from "@mui/icons-material/Search";
import BlockIcon from "@mui/icons-material/Block";
import AlertBoxUnAssign from "../../../components/layout/alertBoxUnAssign";
import { useToasts } from "react-toast-notifications";
import * as XLSX from "xlsx";

const theme = createTheme();

theme.typography.h3 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "1.8rem",
  fontWeight: 400,
  fontStyle: "normal",

  "@media (max-width:850px)": {
    fontSize: "1.5rem",
  },
  "@media (max-width:400px)": {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.8rem",
  },
};
theme.typography.h1 = {
  typography: {
    fontFamily: ["Poppins"].join(","),
  },

  fontSize: "2.5rem",
  color: "#5016BF",
  "@media (max-width:800px)": {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.4rem",
  },
};

const SearchDiv = {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  width: "100px",
  width: "35%",
  margin: "24px 0 0 auto",
};

function Newrider() {
  const { addToast } = useToasts();

  const [openModel, setOpenModel] = useState(false);
  // openConform
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [AllData, setAllData] = useState([]);
  const Auth = localStorage.getItem("userInfor");
  const message = JSON.parse(Auth);
  const token = message.message.token;
  const AdminId = message.message.id;
  const [SearchWord, setSearchWord] = useState("");

  const itemsPerPage = 50;

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [Disabled, setDisabled] = React.useState(false);
  const [selectRow, setSelectRow] = React.useState(null);
  const [selectMultiDeleteRow, setSelectMultiDeleateRow] = React.useState([]);

  useEffect(() => {
    if (AdminId) {
      axios
        .get("/riderdata/details/" + AdminId)
        .then((res) => {
          setData(res.data);
          setAllData(res.data);
          setLoading(true);
        })
        .catch((error) => {
          setLoading(true);
        });
    }
  }, [AdminId]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(AllData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data, AllData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;

    setItemOffset(newOffset);
  };

  const deleteHandle = (e) => {
    axios
      .delete(`/riderdata/${e.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        addToast(
          res.status === 200 ? "Data Deleted Successfully" : "Data Not Delted",
          {
            appearance: res.status === 200 ? "success" : "error",
            autoDismiss: "true",
            autoDismissTimeout: 2000,
          }
        );
        setTimeout(() => {
          window.location.href = "/assigned-data";
        }, 1000);
      })
      .catch((error) => {
        setLoading(true);
      });
  };
  const Deleting = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AlertBox
            onClose={onClose}
            onClick={() => {
              deleteHandle(e);
            }}
          />
        );
      },
    });
  };

  const handleMulatiDeleateRow = (rowId) => {
    if (selectMultiDeleteRow.includes(rowId)) {
      setSelectMultiDeleateRow(
        selectMultiDeleteRow.filter((id) => id !== rowId)
      );
    } else {
      setSelectMultiDeleateRow([...selectMultiDeleteRow, rowId]);
    }
  };

  const handleMultipleDelete = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AlertBox
            onClose={onClose}
            onClick={() => {
              selectMultiDeleteRow.map((id) => {
                deleteHandle(id);
              });
            }}
          />
        );
      },
    });
  };

  const UnAssign = (e) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <AlertBoxUnAssign
            onClose={onClose}
            onClick={() => {
              unAssignHandle(e);
            }}
          />
        );
      },
    });
  };
  const unAssignHandle = (e) => {
    if(e.customsRange === null && e.excelIDRange === null){
    const jsonData = {
      type: e.type,
      dataFrom: Number(e.dataFrom),
      dataTo: Number(e.dataTo),
      riderId: e.riderId,
      createdDate: "2022-10-14",
      dueDate: "2022-10-15",
      jobId: e.jobId,
      typeOfTaskAssign: "Un Assign",
      assignedDate: moment(e.assignedDate).format("YYYY-MM-DD"),
    };

    axios
      // detailIndex
      .post(`/riderdata`, jsonData, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        // Some data already assigned to riders
        window.location.href = "/assigned-data";

        setDisabled(false);
      })
      .catch((error) => {
        addToast("There was an error", {
          appearance: "error",
          autoDismiss: "true",
          autoDismissTimeout: 2000,
        });
        setDisabled(false);
      });
    } else {
      const jsonData = {
        type: e.type,
        dataFrom: Number(e.dataFrom),
        dataTo: Number(e.dataTo),
        riderId: e.riderId,
        createdDate: "2022-10-14",
        dueDate: "2022-10-15",
        jobId: e.jobId,
        typeOfTaskAssign: "Un Assign",
        assignedDate: moment(e.assignedDate).format("YYYY-MM-DD"),
        customsRange: e.customsRange,
        excelIDRange: e.excelIDRange,
      };
  
      axios
        // detailIndex
        .post(`/riderdata`, jsonData, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          // Some data already assigned to riders
          window.location.href = "/assigned-data";
  
          setDisabled(false);
        })
        .catch((error) => {
          addToast("There was an error", {
            appearance: "error",
            autoDismiss: "true",
            autoDismissTimeout: 2000,
          });
          setDisabled(false);
        });
    }
  };

  const searchHandle = (e) => {
    setSearchWord(e.target.value);
    const searching =
      data.filter(
        (item) =>
          String(item.jobName).startsWith(e.target.value) ||
          String(item.fullName).startsWith(e.target.value)
      ) || [];
    setAllData(searching);
  };
  const generateExcel = (data) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(data);

    var today = new Date();
    var todate = today.toLocaleDateString() + " " + today.toLocaleTimeString();
    var fileName = "Rider Assigned Data " + todate + ".xlsx";

    XLSX.utils.book_append_sheet(wb, ws, "Rider Assigned Data");
    XLSX.writeFile(wb, fileName);
  };

  const removeZ = (str) => {
    // var b = "e";
    // var removeLastChar = "";
    // removeLastChar = str.slice(0, str.length - 1);
    // return removeLastChar;
    const updatedAt = new Date(str);
    updatedAt.setMinutes(updatedAt.getMinutes());
    const formattedDate = `${updatedAt.getFullYear()}-${(
      updatedAt.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${updatedAt
      .getDate()
      .toString()
      .padStart(2, "0")} ${updatedAt.toLocaleTimeString()}`;
    return formattedDate;
  };

  const handleExcel = () => {
    // fetch the data from the API
    axios
      .get("/riderdata/details/" + AdminId)
      .then((res) => {
        const data = res.data;
        // modify the data as needed
        // for example, add a new field to each object in the data
        const modifiedData = data.map((item) => ({
          ...item,
          assignedDate: removeZ(item.assignedDate),
        }));
        // generate the excel sheet with the modified data
        generateExcel(modifiedData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRowSelection = (rowDetails) => {
    setSelectRow(rowDetails);
    localStorage.setItem("riderDetails", JSON.stringify(rowDetails));
  };

  const rowDetails = localStorage.getItem("riderDetails");
  const parsedDetails = JSON.parse(rowDetails);

  return (
    <Layout title="Rider">
      <div className="rider-tble">
        <div className="rider-head-details">
          <div className="rider-info">Rider Assigned Data</div>
          <button className="button-cus">
            <Link
              style={{ textDecoration: "none", color: "#501abf" }}
              to="/assign-data"
            >
              Assigning Data
            </Link>
          </button>
        </div>
        <div className="row container1">
          <button className="excel1" onClick={handleExcel}>
            Excel
          </button>
          <div style={SearchDiv}>
            <SearchInput
              icon={<SearchIcon />}
              onChange={(e) => searchHandle(e)}
              value={SearchWord}
              placeholder="Search By Jobname or Fullname"
            />
          </div>
        </div>
        {selectMultiDeleteRow.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "30px",
            }}
          >
            <button
              style={{
                backgroundColor: "#501abf",
                border: "none",
                padding: "15px 25px",
                borderRadius: "50px",
                color: "#fff",
              }}
              onClick={handleMultipleDelete}
            >
              Delete
            </button>
          </div>
        )}
        <div className="table-rider">
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              borderSpacing: 0,
            }}
          >
            <tbody>
              <tr>
                <th>
                  <Typography variant="body"></Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">#</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Image</Typography>{" "}
                </th>

                <th>
                  <Typography variant="body">Fullname</Typography>{" "}
                </th>
                <th>
                  {" "}
                  <Typography variant="body">Category</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Jobname</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body" style={{ padding: "0px" }}>
                    Type Of Task Assign
                  </Typography>{" "}
                </th>
                <th>
                  <Typography variant="body" style={{ padding: "0px" }}>
                    Assigned Date
                  </Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">From</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">To</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Batch</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Total</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Complete</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body">Action</Typography>{" "}
                </th>
                <th>
                  <Typography variant="body" style={{ padding: "0px" }}>
                    Un Assign
                  </Typography>{" "}
                </th>
                <th>
                  <Typography variant="body" style={{ padding: "0px" }}>
                    Delete
                  </Typography>{" "}
                </th>
              </tr>
            </tbody>
            <tbody>
              {!Loading && (
                <tr
                  style={{
                    borderCollapse: "collapse",
                    padding: "0px !important",
                  }}
                >
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      padding: "0px !important",
                    }}
                    colSpan="15"
                  >
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress />
                    </Box>
                  </td>
                </tr>
              )}
            </tbody>

            <tbody>
              {currentItems &&
                currentItems.length > 0 &&
                currentItems.map((data, i) => (
                  <tr key={i}>
                    <td>
                      {/* <Link to="/call-manager">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          style={{
                            cursor: "pointer",
                            width: "20px",
                            height: "20px",
                          }}
                          checked={parsedDetails?.id === data.id}
                          onChange={() => handleRowSelection(data)}
                        />
                      </Link> */}
                      {data.batchName ? (
                        <Link
                          to={`/updateAssign-data/${data.id}/${data.jobId}/${data.fullName}/${data.jobName}`}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            style={{
                              cursor: "pointer",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        </Link>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <Typography variant="body">{i + 1}</Typography>
                    </td>
                    <td align="center">
                      <Avatar
                        sx={{
                          width: "40px",
                          height: "40px",
                          textTransform: "capitalize",
                          textAlign: "center",
                          margin: "0 auto",
                        }}
                        src={axios.defaults.baseURL + "/images/" + data.image}
                        alt="user"
                      >
                        {data.fullName[0]}
                      </Avatar>
                    </td>
                    <td>
                      <p
                        style={{
                          textAlign: "left",
                          margin: "0px",
                          paddingLeft: "16px",
                        }}
                      >
                        {data.fullName}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          textAlign: "left",
                          margin: "0px",
                          paddingLeft: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        {data.category === "lnds" ? "Landed" : data.category}
                      </p>
                    </td>

                    <td>
                      <p
                        style={{
                          textAlign: "left",
                          margin: "0px",
                          paddingLeft: "16px",
                        }}
                      >
                        {data.jobName}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          textAlign: "left",
                          margin: "0px",
                          paddingLeft: "16px",
                        }}
                      >
                        {data.typeOfTaskAssign}
                      </p>
                    </td>
                    <td>
                      <Typography variant="body">
                        {data.assignedDate
                          ? moment(data.assignedDate).format("DD/MM/YYYY")
                          : "----"}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body">
                        {data.dataFrom ? data.dataFrom : "-"}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body">
                        {data.dataTo ? data.dataTo : "-"}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body">
                        {data.batchName ? (
                          <p
                            style={{
                              textAlign: "center",
                              marginTop: "25px",
                              backgroundColor: "#0D9276",
                              color: "#fff",
                              borderRadius: "20px",
                              padding: "5px 20px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {data.batchName}
                          </p>
                        ) : (
                          <p
                            style={{
                              textAlign: "center",
                              marginTop: "25px",
                            }}
                          >
                            -
                          </p>
                        )}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body">
                        {data.customsRangeCount === 0
                          ? data.totalCount
                          : data.customsRangeCount}
                      </Typography>
                    </td>
                    <td>
                      <Typography variant="body">
                        {data.updatedCount ? data.updatedCount : '0'}
                      </Typography>
                    </td>

                    <td onClick={() => Deleting(data)}>
                      <DeleteIcon
                        sx={{
                          color: "red",
                          opacity: "0.8",
                          ": &hover": { opacity: "1" },
                          cursor: "pointer",
                        }}
                      />
                    </td>
                    <td onClick={() => UnAssign(data)}>
                      <BlockIcon
                        sx={{
                          color: "red",
                          opacity: "0.8",
                          ": &hover": { opacity: "1" },
                          cursor: "pointer",
                        }}
                      />
                    </td>
                    <td>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        style={{
                          cursor: "pointer",
                          width: "15px",
                          height: "15px",
                        }}
                        onChange={() => handleMulatiDeleateRow(data)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
            <tbody>
              {data.length === 0 && Loading && (
                <tr style={{ borderCollapse: "collapse" }}>
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                    }}
                    colSpan="15"
                  >
                    <Typography variant="body">No data</Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <br />
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>

      {openModel && <AddRider closeModel={setOpenModel} />}
    </Layout>
  );
}

export default Newrider;
