import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css"; // import first
import { useToasts } from "react-toast-notifications";
import axios from "../../../axios";
import "./importManger.css";
import Layout from "../../../components/layout/Navbar";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import Datapicker from "../../../components/customCore/datapicker";
import { From } from "devextreme-react/autocomplete";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import ReactPaginate from "react-paginate";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  Progress: {
    width: "24px !important",
    height: "24px !important",
  },
  formMain: {
    display: "flex",
    justifyContent: "space-evenly",
    maxWidth: "800px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  formMain1: {
    display: "flex",
    maxWidth: "800px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  formMain2: {
    display: "flex",
    maxWidth: "800px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    justifyContent: "space-between",
  },
  label: {
    paddingLeft: "22px",
    fontSize: "16px",
    color: "#00000A",
    opacity: "0.6",
    fontWeight: 600,
    paddingBottom: "4px",
    width: "200px",
  },
  label1: {
    paddingLeft: "22px",
    fontSize: "16px",
    color: "#00000A",
    opacity: "0.6",
    fontWeight: 600,
    paddingBottom: "4px",
    width: "200px",
    marginTop: "25px",
  },
  lnds: {
    maxWidth: "500px",
  },
  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "200px !important",
    border: "2px solid #501abf !important",
    outline: "none",
    "&:hover": {
      color: "#501abf !important",

      "& svg": {
        color: "#501abf !important",
      },
      background: "#fff !important",
    },
    "& svg": {
      color: "#fff !important",
    },
  },
}));

export const TypeArray = [
  {
    id: "New",
    name: "New",
  },
  // {
  //   id: "Re Assign",
  //   name: "Re Assign(Task Completed)",
  // },
];
function Dashboard() {
  const { addToast } = useToasts();
  const classes = useStyles();

  //upload file
  const [Date, setDate] = React.useState("");

  const [Form, setForm] = React.useState("");
  const [FormRange, setFormRange] = React.useState("");
  const [TypeOfTask, setTypeOfTask] = React.useState("");
  const [To, setTo] = React.useState("");
  const [ToRange, setToRange] = React.useState("");
  const [BatchnameRange, setBatchNameRange] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [dateUpdate, setDateUpdate] = React.useState("");
  const [staffId, setStaffId] = React.useState("");
  // const [jobId, setJobId] = React.useState("");
  const [RiderIdRange, setRiderIdRange] = React.useState("");
  const AdminId = JSON.parse(localStorage.getItem("auth")).message.id || "";
  const [UserSelectCategory, setUserSelectCategory] = React.useState("");
  const [Option, setOption] = useState("");
  const [Disabled, setDisabled] = React.useState(false);
  const [DisabledForRange, setDisabledForRange] = React.useState(false);
  const [DisabledForRangeSubmit, setDisabledForRangeSubmit] =
    React.useState(false);
  const [customIdRange, setCustomIdRange] = useState(false);
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [AllData, setAllData] = useState([]);
  const [selectMultiDeleteRow, setSelectMultiDeleateRow] = React.useState([]);
  const { id, jobId, name, jobName } = useParams();
  const [selectedCustomIds, setSelectedCustomIds] = useState("");
  const [selectedExcelIds, setSelectedExcelIds] = useState("");
  const [checkedRows, setCheckedRows] = useState([]);
  const [riderId, setRiderId] = useState("");

  useEffect(() => {
    axios
      .get(`/riderdata/${id}`)
      .then((res) => {
        setCategory(res.data.type);
        setTypeOfTask(res.data.typeOfTaskAssign);
        setDateUpdate(res.data.assignedDate);
        setBatchNameRange(res.data.batchName);
        setSelectedCustomIds(res.data.customsRange);
        setSelectedExcelIds(res.data.excelIDRange);
        setFormRange(res.data.dataFrom);
        setToRange(res.data.dataTo);
        setRiderId(res.data.riderId);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const handleButtonClick = () => {
    setDisabledForRange(true);
    const diffrenceOfRange = Number(ToRange) - Number(FormRange);
    if (FormRange === "" || ToRange === "") {
      addToast("Please select the range.", {
        appearance: "error",
        autoDismiss: "true",
        autoDismissTimeout: 3000,
      });
      setDisabledForRange(false);
    } else if (Number(FormRange) > Number(ToRange)) {
      addToast("From range is greater than To range", {
        appearance: "error",
        autoDismiss: "true",
        autoDismissTimeout: 3000,
      });
      setDisabledForRange(false);
    } else if (diffrenceOfRange > 300) {
      addToast("The difference between 'To' and 'From' exceeds 300", {
        appearance: "error",
        autoDismiss: "true",
        autoDismissTimeout: 3000,
      });
      setDisabledForRange(false);
    } else {
      axios
        .get(
          `/riderdata/filterByJobnameAndCategoryCallManager/${category}/${jobId}/2/All/All/${Number(
            FormRange
          )}/${Number(ToRange)}`
        )
        .then((res) => {
          setData(res.data);
          setAllData(res.data);
          setLoading(true);
          setDisabledForRange(false);
          setCheckedRows(
            res.data.map((row) => Boolean(row.assignedId || false))
          );
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setDisabledForRange(false);
        });
    }
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(AllData.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data, AllData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;

    setItemOffset(newOffset);
  };

  const handleCheckboxChange = (e) => {
    setCustomIdRange(e.target.checked);
  };

  const handleCheckboxChange1 = (index) => {
    // Toggle the checked status for the specific row
    const newCheckedRows = [...checkedRows];
    newCheckedRows[index] = !newCheckedRows[index];
    setCheckedRows(newCheckedRows);

    // Handle any other logic, like updating the API or local state for the specific row
    handleMulatiDeleateRow(data[index]);
  };

  const handleMulatiDeleateRow = (rowId) => {
    if (selectMultiDeleteRow.includes(rowId)) {
      setSelectMultiDeleateRow(
        selectMultiDeleteRow.filter((id) => id !== rowId)
      );
    } else {
      setSelectMultiDeleateRow([...selectMultiDeleteRow, rowId]);
    }
  };

  const handleSubmitForCustomRange = () => {
    // setDisabledForRangeSubmit(true);
    const rowIds = selectMultiDeleteRow.map((row) => row.id);

    const formatedSelectedCustonIds = JSON.parse(selectedCustomIds);

    // Remove common element from both arrays
    const uniqueRowIds = rowIds.filter(
      (id) => !formatedSelectedCustonIds.includes(id)
    );
    const uniqueSelectedIds = formatedSelectedCustonIds.filter(
      (id) => !rowIds.includes(id)
    );

    // Merge the arrays
    const mergedArray = [...uniqueRowIds, ...uniqueSelectedIds];
    const stringRowIds = JSON.stringify(mergedArray);

    const excelIds = selectMultiDeleteRow.map((row) => row.excelId);
    const formatedSelectedExcelIds = JSON.parse(selectedExcelIds);

    const uniqueExcelIds = excelIds.filter(
      (id) => !formatedSelectedExcelIds.includes(id)
    );
    const uniqueSelectedExcelIds = formatedSelectedExcelIds.filter(
      (id) => !excelIds.includes(id)
    );

    const mergedExcelArray = [...uniqueExcelIds, ...uniqueSelectedExcelIds];
    const stringExcelIds = JSON.stringify(mergedExcelArray);

    const jsonData = {
      customsRange: stringRowIds,
      excelIDRange: stringExcelIds,
      batchName: BatchnameRange,
      riderId: riderId,
      jobId:jobId,
      type: category
    };
    console.log(jsonData);
    axios
      // detailIndex
      .put(`/riderdata/updateCustomRange/${id}`, jsonData, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        // Some data already assigned to riders
        addToast(
          res.data.errorMessage === true
            ? "Some data already assigned to riders"
            : "UPdated Data Successfully",
          {
            appearance: res.data.errorMessage === true ? "error" : "success",
            autoDismiss: "true",
            autoDismissTimeout: 2000,
          }
        );
        setTimeout(() => {
          window.location.href = "/assigned-data";
        }, 1000);
        setDisabledForRangeSubmit(false);
      })
      .catch((error) => {
        addToast("please select correct feild", {
          appearance: "error",
          autoDismiss: "true",
          autoDismissTimeout: 2000,
        });
        setDisabledForRangeSubmit(false);
      });
  };

  return (
    <Layout>
      <div className="newFile2  form">
        <div className="rider-info">Update the Assign Data</div>
        <form>
          <div className={classes.formMain}>
            <div className="row">
              <label className={classes.label}>Category</label>
              <input
                type="text"
                className="input-div-input"
                placeholder="Category"
                value={category === "lnds" ? "Landed" : category}
                readOnly
              />
              <br />
              <label className={classes.label1}>Job Name</label>
              <input
                type="text"
                className="input-div-input"
                placeholder="Job name"
                value={jobName}
                readOnly
              />
              <br />
              <label className={classes.label1}>Staff Name</label>
              <input
                type="text"
                className="input-div-input"
                placeholder="Staff name"
                value={name}
                readOnly
              />
            </div>
            <br />
            <hr />
            <br />
            <br />

            <div className="container1">
              <label className={classes.label}> Type Of Task Assign</label>
              <input
                type="text"
                className="input-div-input"
                placeholder="Type of task"
                value={TypeOfTask}
                readOnly
              />
              <br />
              <br />
              <label className={classes.label}>Date</label>
              <input
                type="text"
                className="input-div-input"
                placeholder="Date"
                value={dateUpdate.split("T")[0]}
                readOnly
              />
              <br />
              <br />
              <label className={classes.label} style={{ marginTop: "20px" }}>
                Batch Name
              </label>
              <div className="input-div">
                <input
                  type="text"
                  className="input-div-input"
                  placeholder="Batch Name"
                  value={BatchnameRange}
                  readOnly
                />
              </div>
            </div>
          </div>
          <hr style={{ margin: "30px 80px" }} />
          <div className={classes.formMain1}>
            <div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault1"
                  style={{ cursor: "pointer" }}
                  onChange={handleCheckboxChange}
                />
                <label
                  for="flexCheckDefault1"
                  className={classes.label}
                  style={{ marginTop: "5px", cursor: "pointer" }}
                >
                  Assign more jobs
                </label>
              </div>
            </div>
            <div className="container1">
              <div style={{ display: "flex", maxWidth: "300px" }}>
                <label style={{ marginTop: "10px" }} className={classes.label}>
                  From
                </label>

                <div className="input-div">
                  <input
                    type="text"
                    className="input-div-input"
                    placeholder="From"
                    value={FormRange}
                    style={{ width: "100px", marginLeft: "10px" }}
                    disabled={!customIdRange}
                  />
                </div>
                <br />
                <label style={{ marginTop: "10px" }} className={classes.label}>
                  To
                </label>

                <div className="input-div">
                  <input
                    type="text"
                    className="input-div-input"
                    placeholder="To"
                    value={ToRange}
                    style={{ width: "100px", marginLeft: "10px" }}
                    disabled={!customIdRange}
                  />
                </div>
              </div>
            </div>
            <div className="container1">
              <div style={{ marginLeft: "100px", marginTop: "5px" }}>
                <button
                  type="button"
                  style={{
                    border: "none",
                    borderRadius: "50%",
                    padding: "7px 13px 10px",
                    background: "#5016bf",
                  }}
                  disabled={!customIdRange}
                  onClick={handleButtonClick}
                >
                  {" "}
                  {DisabledForRange ? (
                    <CircularProgress className={classes.Progress} />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#fff"
                      className="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className={classes.formMain2}>
            {data && data.length > 0 && customIdRange && (
              <>
                <div className="row">
                  <div className="table-rider">
                    <table
                      style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                      }}
                    >
                      <tbody>
                        <tr>
                          <th>
                            <Typography variant="body"></Typography>{" "}
                          </th>
                          <th>
                            <Typography variant="body">ID</Typography>{" "}
                          </th>
                          <th>
                            <Typography variant="body">SAN</Typography>{" "}
                          </th>

                          <th>
                            <Typography variant="body">DCA Name</Typography>{" "}
                          </th>
                          <th>
                            {" "}
                            <Typography variant="body">State</Typography>{" "}
                          </th>
                          <th>
                            <Typography variant="body">Status</Typography>{" "}
                          </th>
                          <th>
                            <Typography variant="body">Batch Name</Typography>{" "}
                          </th>
                        </tr>
                      </tbody>
                      <tbody>
                        {!Loading && (
                          <tr
                            style={{
                              borderCollapse: "collapse",
                              padding: "0px !important",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                                padding: "0px !important",
                              }}
                              colSpan="15"
                            >
                              <Box sx={{ width: "100%" }}>
                                <LinearProgress />
                              </Box>
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <tbody>
                        {currentItems &&
                          currentItems.length > 0 &&
                          currentItems.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                  style={{
                                    cursor: "pointer",
                                    width: "15px",
                                    height: "15px",
                                  }}
                                  checked={checkedRows[index]}
                                  onChange={() => handleCheckboxChange1(index)}
                                  disabled={
                                    data.batchName !== null &&
                                    data.batchName !== BatchnameRange
                                  }
                                />
                              </td>
                              <td>
                                <Typography variant="body">
                                  {data.id}
                                </Typography>
                              </td>
                              <td align="center">
                                <p
                                  style={{
                                    textAlign: "left",
                                    margin: "0px",
                                    paddingLeft: "16px",
                                  }}
                                >
                                  {data.SAN}
                                </p>
                              </td>
                              <td>
                                <p
                                  style={{
                                    textAlign: "left",
                                    margin: "0px",
                                    paddingLeft: "16px",
                                  }}
                                >
                                  {data.DCAName}
                                </p>
                              </td>
                              <td>
                                <p
                                  style={{
                                    textAlign: "left",
                                    margin: "0px",
                                    paddingLeft: "16px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {data.State}
                                </p>
                              </td>
                              <td>
                                <p
                                  style={{
                                    textAlign: "left",
                                    margin: "0px",
                                    paddingLeft: "16px",
                                  }}
                                >
                                  {data.assignedId
                                    ? "Assigned"
                                    : "Not Assigned"}
                                </p>
                              </td>
                              <td>
                                {data.batchName ? (
                                  <p
                                    style={{
                                      textAlign: "center",
                                      marginTop: "20px",
                                      backgroundColor:
                                        data.batchName !== null &&
                                        data.batchName !== BatchnameRange
                                          ? "#B4B4B8"
                                          : "#0D9276",
                                      color: "#fff",
                                      borderRadius: "20px",
                                      padding: "5px 20px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {data.batchName}
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      textAlign: "center",
                                      marginTop: "25px",
                                    }}
                                  >
                                    -
                                  </p>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                      <tbody>
                        {data.length === 0 && Loading && (
                          <tr style={{ borderCollapse: "collapse" }}>
                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                              colSpan="15"
                            >
                              <Typography variant="body">No data</Typography>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <br />
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      onPageChange={handlePageClick}
                      pageCount={pageCount}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
                <div className="container1">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      type="button"
                      className={`submit3  ${classes.btn} `}
                      onClick={handleSubmitForCustomRange}
                    >
                      {" "}
                      {DisabledForRangeSubmit ? (
                        <CircularProgress className={classes.Progress} />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </Layout>
  );
}
export default Dashboard;
